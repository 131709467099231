<template>
    <div class="search-bar">
      <input 
        v-model="searchQuery" 
        @input="$emit('search', searchQuery)"
        placeholder="Search..."
        class="search-input"
      />
    </div>
</template>
  
<script>
export default {
    name: 'SearchBar',
    data() {
      return {
        searchQuery: '',
      };
    },
};
</script>

<style scoped>
.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.search-input {
    width: 100%;
    max-width: 400px; /* Adjust the max width as needed */
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input::placeholder {
    color: #aaa; /* Placeholder color */
}

.search-input:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
    outline: none; /* Remove default outline */
}
</style>