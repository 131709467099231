<template>
  <div class="post-list">
    <!-- 添加条件渲染 -->
    <div v-if="!posts || posts.length === 0" class="loading-placeholder">
      <p>Loading...</p>
      <hr>
      <h1>iphone/ipad/mac/visionpro</h1>
      <h2>👉 <a href='https://apps.apple.com/us/app/sankeymaster-sankey-diagram/id6474908221' target='_blank'>SankeyMaster -  Ultimate Sankey Diagram ! </a>👈</h2>
      <p><a href='https://apps.apple.com/us/app/sankeymaster-sankey-diagram/id6474908221' target='_blank'>The ultimate chart-making tool ! support bar, line, area, column, polar bar, pie, rose, radar, beef distribution, organ, sunburst, sankey and more charts !</a></p>

      <h1>online Editor</h1>
      <h2>👉 <a href='https://studio.sankeymaster.com' target='_blank'>SankeyMaster -  Ultimate Sankey Diagram ! </a>👈</h2>
    </div>
    <div v-else>
      <div v-for="post in posts" :key="post.id" class="post-item">
        <router-link :to="`/post/${post.id}`">{{ post.title.replace("<","").replace("/p>","").replace(">","").replace("\"","") }}</router-link>
        <br>
        <br>
        <p v-html="post.content.replace(post.title,'').substring(0, 800) + (post.content.length > 800 ? '...' : '')" class="post-content"></p> <!-- 使用 v-html 显示 HTML 内容 -->
        <router-link :to="`/post/${post.id}`" class="learn-more-button">Learn More</router-link>
        <!---
        <hr>
        <p>date: {{ post.date }}</p>
        <p v-if="post.categories.length > 0">Categories:
          <span v-for="(category, index) in post.categories" :key="index" class="category">
            {{ category }}<span v-if="index < post.categories.length - 1"></span>
          </span>
        </p>
        --->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostList',
  props: {
    posts: Array,
  },
};
</script>

<style scoped>
/* 添加样式 */
.loading-placeholder {
  min-height: 300px; /* 设置最小高度以防止布局变化 */
  display: flex;
  flex-direction: column; /* 垂直排列内容 */
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: #fff; /* White background for contrast */
  border-radius: 8px; /* 圆角 */
  padding: 20px; /* 添加内边距 */
  text-align: center; /* 文本居中 */
}

a:hover {
  color:red;
}

.post-list {
  margin: 20px 0;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
  width: 88%;
}

.post-item {
  margin-bottom: 15px;
  width: 100%; /* Full width for better responsiveness */
  max-width: 800px; /* Limit max width for larger screens */
  padding: 15px; /* Add padding for better spacing */
  border: 1px solid #ddd; /* Add a border for separation */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: #fff; /* White background for contrast */
}

.post-item h2 {
  font-size: 1.5em; /* Adjust title size */
  margin: 0 0 10px; /* Space below title */
}

.post-item p {
  margin: 5px 0; /* Space between paragraphs */
}
.post-content {
  overflow-x: auto; /* 允许水平滚动 */
  word-wrap: break-word; /* 允许长单词换行 */
}

.category, .tag {
  display: inline-block; /* Make them inline-block for better layout */
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  padding: 5px 10px; /* Padding for spacing */
  margin: 5px; /* Margin for spacing between items */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.category:hover, .tag:hover {
  background-color: #d0d0d0; /* Darker gray on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.learn-more-button {
  display: inline-block; /* Make it an inline-block for padding */
  background-color: orange; /* Orange background */
  color: black; /* White text color */
  padding: 5px 10px; /* Padding for the button */
  border-radius: 25px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.learn-more-button:hover {
  background-color: red; /* Darker orange on hover */
  color: white;
}

@media (max-width: 600px) {
  .post-item {
    padding: 10px; /* Reduce padding on mobile */
  }
  .post-item h2 {
    font-size: 1.2em; /* Smaller title size for mobile */
  }
}
</style>