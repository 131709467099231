<template>
    <div class="pagination">
      <button @click="$emit('prev')" :disabled="currentPage === 1" class="pagination-button">Before</button>
      <span class="pagination-info">Page {{ currentPage }}</span>
      <button @click="$emit('next')" :disabled="!hasMore" class="pagination-button">Next</button>
    </div>
</template>

<script>
   /* eslint-disable */
  export default {
    name: 'Pagination',
    props: {
      currentPage: Number,
      hasMore: Boolean,
    },
  };
</script>

<style scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pagination-button {
    background-color: #007bff;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination-info {
    margin: 0 10px;
    font-size: 16px;
    color: #333;
}
</style>