<!-- frontend/src/components/Modal.vue -->
<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <slot></slot> <!-- 保留插槽以展示内容 -->
      <button class="close-button" @click="close">🅧</button> <!-- 修改按钮文本 -->
    </div>
  </div>
</template>

<script>
 /* eslint-disable */
export default {
  name: 'Modal',
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
      localStorage.setItem('modalClosedTime', Date.now()); // 记录关闭时间
    },
    checkModal() {
      
      const closedTime = localStorage.getItem('modalClosedTime');
      if (closedTime) {
        const oneHour = 60 * 60 * 1000; // 一小时的毫秒数
        if (Date.now() - closedTime < oneHour) {
          return; // 如果在一小时内，不再弹出
        }
      }
      this.open(); // 否则打开弹窗
    },
  },
  mounted() {
    this.checkModal(); // 检查是否需要弹出弹窗
  },
};
</script>



<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* 增加透明度 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保弹窗在最上层 */
}

.modal-content {
  color: red;
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative; /* 确保内容相对定位 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
  max-width: 500px; /* 限制最大宽度 */
  width: 90%; /* 适应小屏幕 */
  text-align: center; /* 内容居中 */
}

.modal-content p {
  color: red;
}

.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离顶部的距离 */
  right: 10px; /* 距离右侧的距离 */
  font-size: 28px; /* 设置字体大小 */
  padding: 5px 10px; /* 添加内边距以增加点击区域 */  
  transition: color 0.3s; /* 添加过渡效果 */
}

.close-button:hover {
  color: red; /* 悬浮时变红 */
}

a {
  color: dark; /* 链接颜色 */
  text-decoration: none; /* 去掉下划线 */
}

a:hover {
  color: red; /* 悬浮时变深蓝色 */
}
</style>