// frontend/src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head'

const app = createApp(App);
const head = createHead();

app.use(head)
app.use(router); // 使用路由
app.mount('#app'); // 挂载应用