<template>
  <div>
    <h1>站点地图</h1>
    <ul>
      <li v-for="(url, index) in sitemapUrls" :key="index">
        <router-link :to="url">{{ url }}</router-link> <!-- 使用 router-link 显示 URL -->
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */
import api from '@/services/api'; // 导入 API 服务

export default {
  name: 'Sitemap',
  data() {
    return {
      sitemapUrls: [], // 存储站点地图 URL
    };
  },
  async created() {
    await this.fetchSitemapIndex(); // 组件创建时获取站点地图索引
  },
  methods: {
    async fetchSitemapIndex() {
      try {
        const response = await api.get('/sitemap.xml'); // 调用 API 获取站点地图索引
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
        const sitemapElements = xmlDoc.getElementsByTagName('sitemap');

        // 提取每个 sitemap 的 loc URL
        this.sitemapUrls = Array.from(sitemapElements).map(sitemap => sitemap.getElementsByTagName('loc')[0].textContent);
      } catch (error) {
        console.error('获取站点地图索引失败:', error);
      }
    },
  },
};
</script>

<style scoped>
/* 添加样式 */
h1 {
  font-size: 2em;
}
ul {
  list-style-type: none;
}
li {
  margin: 10px 0;
}
</style>