import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Post from '@/views/Post.vue';
import Sitemap from '@/views/Sitemap.vue'; // 导入站点地图组件
import SitemapDetail from '@/views/SitemapDetail.vue'; // 导入站点地图详情组件
import Page from '@/views/Page.vue'; // 导入新创建的 Page 组件

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/post/:id', name: 'Post', component: Post },
  { path: '/sitemap.xml', name: 'Sitemap', component: Sitemap, meta: { type: 'xml' } }, // 添加 meta 属性
  { path: '/sitemap_post:url', name: 'SitemapDetail', component: SitemapDetail, meta: { type: 'xml' } }, // 添加 meta 属性
  { path: '/page/:page', name: 'Page', component: Page }, // 使用新创建的 Page 组件
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;