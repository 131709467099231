<template>
    <div class="home">
    <Header />    
      <SearchBar @search="search" />
      <PostList :posts="posts" />
      <Pagination 
        :currentPage="currentPage" 
        :hasMore="hasMore"
        @prev="prevPage"
        @next="nextPage"
      />
      <Footer />  
    </div>
  </template>
  
  <script>
   /* eslint-disable */
  import Header from '@/components/Header.vue';
  import Footer from '@/components/Footer.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import PostList from '@/components/PostList.vue';
  import Pagination from '@/components/Pagination.vue';
  import postService from '@/services/postService';
  
  export default {
    name: 'Home',
    components: {
      Header,
      Footer,
      SearchBar,
      PostList,
      Pagination,
    },
    data() {
      return {
        posts: [],
        currentPage: 1,
        hasMore: false,
        searchQuery: '',
      };
    },
    methods: {
      async search(query) {
        this.searchQuery = query;
        this.currentPage = 1;
        await this.fetchPosts();
      },
      async fetchPosts() {
        try {
            const result = await postService.searchPosts(this.searchQuery, this.currentPage);
            this.posts = result.hits; // Ensure this includes categories and tags
            this.hasMore = this.currentPage < result.totalPages; // Correctly set hasMore based on totalPages
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    },
      async prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          await this.fetchPosts();
        }
      },
      async nextPage() {
        if (this.hasMore) {
          this.currentPage++;
          await this.fetchPosts();
        }
      },
    },
    mounted() {
      this.fetchPosts();
    },
  };
  </script>
  
<style scoped>
.home {
  padding: 20px; /* Add padding for better spacing */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align items */
}

h1 {
  font-size: 2em; /* Adjust heading size */
  margin-bottom: 20px; /* Space below the heading */
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5em; /* Smaller heading size for mobile */
  }
  .home {
    padding: 10px; /* Reduce padding on mobile */
  }
}
</style>