<template>
  <Header />    
  <div class="page">
    <PostList :posts="paginatedPosts" />
    <Pagination 
      :currentPage="currentPage" 
      :hasMore="hasMore" 
      @prev="goToPrevPage" 
      @next="goToNextPage" 
    />
  </div>
  <Footer />
</template>

<script>
/* eslint-disable */
import PostList from '@/components/PostList.vue';
import Pagination from '@/components/Pagination.vue';
import postService from '@/services/postService';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Page',
  components: {
    PostList,
    Pagination,
    Header,
    Footer
  },
  data() {
    return {
      posts: [],
      hasMore: false,
    };
  },
  computed: {
    currentPage() {
      return Number(this.$route.params.page) || 1;
    },
    paginatedPosts() {
      const itemsPerPage = 10;
      const start = (this.currentPage - 1) * itemsPerPage;
      return this.posts;
    },
  },
  methods: {
    async fetchPosts() {
      try {
        const result = await postService.fetchPosts(this.currentPage);
        this.posts = result.hits;
        this.hasMore = this.currentPage < result.totalPages;
        console.log(this.posts);
        this.updateMetaTags(); // {{ edit_1 }} Call to update meta tags
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    },
    updateMetaTags() { // {{ edit_2 }} Method to update meta tags
      if (this.posts.length > 0) {
        const firstPost = this.posts[0];
        document.title = firstPost.title || 'Default Title'; // Use the title of the first post

        const uniqueTags = Array.from(new Set(
          this.posts.flatMap(post => post.tags || [])
        )).join(', '); // Generate unique keywords from all posts

        const metaTags = [
          { name: 'title', content: firstPost.title.replace(/<[^>]+>/g, '').replace('"','') || 'ChartStudio' },
          { name: 'description', content: firstPost.content.replace(/<[^>]+>/g, '').substring(0, 160) + '...' || 'Default description' },
          { name: 'og:title', content: firstPost.title.replace(/<[^>]+>/g, '').replace('"','') || 'ChartStudio' },
          { name: 'og:description', content: firstPost.content.replace(/<[^>]+>/g, '').substring(0, 160) + '...' || 'Default description' },          
          { name: 'keywords', content: uniqueTags || 'Default keywords' },
        ];

        metaTags.forEach(({ name, content }) => {
          let meta = document.querySelector(`meta[name="${name}"]`);
          if (!meta) {
            meta = document.createElement('meta');
            meta.name = name;
            document.head.appendChild(meta);
          }
          meta.content = content;
        });
      }
    },
    goToPrevPage() {
      if (this.currentPage > 1) {
        this.$router.push(`/page/${this.currentPage - 1}`);
      }
    },
    goToNextPage() {
      this.$router.push(`/page/${this.currentPage + 1}`);
    },
  },
  watch: {
    '$route.params.page': {
      immediate: true,
      handler() {
        this.fetchPosts();
      },
    },
  },
};
</script>

<style scoped>
.page {
  padding: 20px; /* Add padding for better spacing */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align items */
}

h1 {
  font-size: 2em; /* Adjust heading size */
  margin-bottom: 20px; /* Space below the heading */
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5em; /* Smaller heading size for mobile */
  }
  .home {
    padding: 10px; /* Reduce padding on mobile */
  }
}
</style>